import React from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { BUS_ACCESS_TOKEN, SubscriptionHandle } from '../../../common/auth/bus-topics';
import { BasicModal, BasicModalBody, BasicModalFooter, BasicModalHeader } from '../../../common/components/BasicModal';
import { SvgIcon } from '../../../common/components/SvgIcon';
import { IWidgetConfigurationContext, WidgetConfigurationContext } from '../../../common/configuration';
import { registerSplashScreenEvent } from '../../../common/monitoring';
import { Translator } from '../../../common/sgwt-i18n';
import {
  checkResponseStatus,
  emptyObject,
  getAuthorizationToken,
  whichEnvironment,
} from '../../../common/sgwt-widgets-utils';
import { UserFeedbackReview, UserFeedbackReviewResponse } from '../../../common/types';
import {
  FeedbackResponse,
  IActiveSplashScreen,
  IServiceBoardDisclaimer,
  ISplashScreen,
} from '../sgwt-splash-screen.types';
import { ISplashScreenDisclaimerInfo, SPLASH_SCREEN_ENDPOINTS } from '../shared/splash-screen.endpoints';
import { FeedbackContent } from './FeedbackContent';
import SplashScreensCarousel from './SplashScreensCarousel';

interface ISplashScreenProps {
  applicationId: string;
  debug?: boolean;
  manualActivation?: boolean;
  mode: string;
  onHide: () => void;
  onNotShown: () => void;
  onShow: () => void;
  translator: Translator;
  visible?: boolean;
}

interface SplashScreenState {
  gotFirstToken: boolean;
  history: ISplashScreen[];
  loadingHistory: boolean;
  screen: ISplashScreen | null;
  show: boolean;
  feedbackResponse: FeedbackResponse;
}

export default class SplashScreen extends React.Component<ISplashScreenProps, SplashScreenState> {
  static contextType = WidgetConfigurationContext;
  private widgetConfiguration: WidgetConfiguration;
  private sgConnectV2TokenSubscription?: SubscriptionHandle;
  // private authToken?: string;
  private apiEndpoint!: string;
  private disclaimerInfo!: ISplashScreenDisclaimerInfo;
  private feedbackEndpoint!: string;
  private activeScreenToDisplay = false;

  constructor(props: ISplashScreenProps, context: IWidgetConfigurationContext) {
    super(props);
    this.widgetConfiguration = context!.widgetConfiguration;
    this.state = {
      gotFirstToken: false,
      history: [],
      loadingHistory: false,
      screen: null,
      show: false,
      feedbackResponse: null,
    };
  }

  private logDebug(...messages: any[]) {
    if (this.props.debug) {
      this.widgetConfiguration.log('[sgwt-splash-screen::debug]', ...messages);
    }
  }

  /**
   * Set the API Endpoint corresponding to the current environment.
   */
  private configureComponent() {
    const environment = whichEnvironment(this.widgetConfiguration);
    const endpoints = SPLASH_SCREEN_ENDPOINTS[environment];
    this.apiEndpoint = endpoints.api;
    this.disclaimerInfo = endpoints.disclaimer;
    this.feedbackEndpoint = endpoints.feedback;
    this.logDebug(`API endpoint ${this.apiEndpoint} for environment ${environment}.`);

    // This flag indicates that the active screen should be displayed to the user.
    // It may happens that the authentication is not yet accomplished when the component is mounted, which
    // will make the display fails. Thus, once the authentication is done, we check the flag, which means that
    // we will try to redisplay the active screen.
    this.activeScreenToDisplay = !this.props.manualActivation;
  }

  /**
   * The component has been updated. Potentially this has been called by the skateJs component (sgwt-splash-screen.tsx)
   * with the `show()` or `showHistory()` function. Therefore, we need to check if the screen has to be displayed...
   */
  public componentDidUpdate(previousProps: ISplashScreenProps) {
    let callHistory = false;
    // The component just switched to "history" mode, so we retrieve the screens.
    if (this.props.mode === 'history' && previousProps.mode !== 'history') {
      this.retrieveHistoryScreens();
      callHistory = true;
    }
    if (this.props.visible && !this.state.show) {
      this.setState({ show: true });
      // Do we have to retrieve history again? For example when we closed the history, and ask to show it again...
      if (this.props.mode === 'history' && !callHistory) {
        this.retrieveHistoryScreens();
      }
      if (this.props.mode === 'active') {
        this.retrieveActiveScreen();
      }
    } else {
      this.props.onNotShown();
    }
  }

  /**
   * A SG Connect token has been found (either by `window.sgwtConnect` or the Widgets bus).
   * If this is the first token we got, we call the API to know if a new screen has to be displayed to the user...
   */
  private gotFirstToken(token: string | null | undefined, origin: string) {
    this.logDebug(`Token updated: ${token} (${origin}). Is first one received? ${!this.state.gotFirstToken}`);
    if (!!token && !this.state.gotFirstToken && this.activeScreenToDisplay) {
      this.setState({ gotFirstToken: true }, () => {
        this.retrieveActiveScreen();
      });
    }
  }

  /**
   * The component is mounted. We request the token in order to get the active screen.
   */
  public componentDidMount() {
    this.configureComponent();
    const token = getAuthorizationToken(this.widgetConfiguration);
    if (token) {
      this.gotFirstToken(token, 'initial connection');
    } else {
      // We didn't get the token, so we subscribe to the Widget bus, in case we have it some times later...
      this.sgConnectV2TokenSubscription = this.widgetConfiguration.bus.subscribe<string>(
        BUS_ACCESS_TOKEN,
        (token: string | undefined) => {
          this.gotFirstToken(token, 'Widgets Bus');
        },
      );
      // In addition to that, we also add a check 2 seconds after, to handle the case where the sgwtConnect library
      // is used WITHOUT the <sgwt-connect> widget (meaning the bus will NOT be updated), but the SplashScreen is loaded
      // too early compared to the application that creates the sgwtConnect.
      setTimeout(() => {
        const token = getAuthorizationToken(this.widgetConfiguration);
        this.gotFirstToken(token, 'delayed initialization');
      }, 2000);
    }
  }

  /**
   * The component will be unmounted, so we have to unsubscribe to bus subscriptions for SG Connect v2.
   */
  public componentWillUnmount() {
    if (this.sgConnectV2TokenSubscription) {
      this.widgetConfiguration.bus.unsubscribe(this.sgConnectV2TokenSubscription);
      this.logDebug('Component unmounted, subscription to bus cancelled');
    }
  }

  /**
   * Call the API of SGWT Services with SG Connect v2 Authentication - with Authorization Token.
   */
  private async callSplashScreenAPI<TPayload, TOutput>(
    action: 'active' | 'history' | 'disclaimer' | 'feedback',
    url: string,
    method: string,
    data?: TPayload,
  ): Promise<TOutput> {
    const token = getAuthorizationToken(this.widgetConfiguration);

    if (!token) {
      this.widgetConfiguration.log('could not call Splash Screen API since we do not have SG Connect token.');
      throw new Error('could not call Splash Screen API since we do not have SG Connect token.');
    }
    return fetch(url, {
      headers: new Headers({
        Authorization: token,
        'Content-Type': 'application/json',
      }),
      method,
      body: data ? JSON.stringify(data) : undefined,
    })
      .then(checkResponseStatus)
      .then((response: { json: () => any }) => response.json() as TOutput)
      .catch((err: { message: string | null | undefined }) => {
        registerSplashScreenEvent(`api-${action}.error`, err.message);
        throw err;
      });
  }

  /**
   * Retrieve an active screen for the current user and check the disclaimer status.
   */
  private async retrieveActiveScreen() {
    this.logDebug('retrieve active screen');
    const splash = await this.callSplashScreenAPI<never, IActiveSplashScreen>(
      'active',
      `${this.apiEndpoint}/splash-screens/active/${this.props.applicationId}`,
      'GET',
    );
    this.logDebug('active screen found:', splash);
    const disclaimerOK = await this.checkDisclaimer(splash);
    if (disclaimerOK) {
      this.retrieveScreen(splash.screen);
    }
  }

  /**
   * Call the Disclaimer API if needed. Then, check for country restrictions and disclaimers
   * to validate.
   * @returns a boolean at `true` if everything is OK, at `false` otherwise.
   */
  private async checkDisclaimer(splash: IActiveSplashScreen): Promise<boolean> {
    if (splash.disclaimer && splash.disclaimerApplication && this.disclaimerInfo) {
      // if disclaimer set to true and application Id set call the disclaimer api endpoint
      const application = splash.disclaimerApplication;
      if (application) {
        // Check the disclaimer status for the current user (SG Connect V2)
        const disclaimerStatus: IServiceBoardDisclaimer | null = await this.callSplashScreenAPI<
          never,
          IServiceBoardDisclaimer
        >('disclaimer', `${this.disclaimerInfo.api}${application}`, 'GET');
        this.logDebug('Disclaimer API response:', disclaimerStatus);
        // If the user is part of a restricted country, we redirect it to the dedicated page.
        if (disclaimerStatus && disclaimerStatus.HasRestrictiveCountry) {
          registerSplashScreenEvent('disclaimer.restrictive-country');
          window.location.href = this.disclaimerInfo.restrictedCountry;
          return false;
        }
        // If disclaimer not accepted redirect to the disclaimer page and exit.
        if (disclaimerStatus && disclaimerStatus.NeedsDisclaimerValidation) {
          registerSplashScreenEvent('disclaimer.validation-required');
          const redirectUri = window.location.href;
          window.location.href = `${this.disclaimerInfo.page}/${application}?ReturnUrl=${encodeURIComponent(
            redirectUri,
          )}`;
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Retrieve an active screen for the current user. If it exists, it will be displayed and acknowledged.
   */
  private retrieveScreen(splashScreen?: ISplashScreen) {
    this.logDebug('retrieve screen');
    this.activeScreenToDisplay = false;
    if (!splashScreen || emptyObject(splashScreen)) {
      this.props.onNotShown();
      this.setState({
        show: false,
        screen: null,
      });
    } else {
      this.props.onShow();
      registerSplashScreenEvent('active-screen.display', splashScreen.name);
      this.setState(
        {
          show: true,
          screen: splashScreen,
        },
        () => {
          this.callSplashScreenAPI(
            'active',
            `${this.apiEndpoint}/splash-screens/active/${this.props.applicationId}/${splashScreen.name}`,
            'POST',
            null,
          );
        },
      );
    }
  }

  /**
   * Retrieve the history screens for the current user. If at least one screen exists, the history will be displayed.
   */
  private retrieveHistoryScreens() {
    this.logDebug('retrieve history screens');
    this.setState(
      {
        loadingHistory: true,
      },
      async () => {
        const history: ISplashScreen[] = await this.callSplashScreenAPI<never, ISplashScreen[]>(
          'history',
          `${this.apiEndpoint}/splash-screens/history/${this.props.applicationId}`,
          'GET',
        );
        this.logDebug('history screens found:', history);
        this.activeScreenToDisplay = false;
        this.setState({
          history: history,
          loadingHistory: false,
        });
      },
    );
  }

  private sendUserFeedback = async (screen: ISplashScreen, rating: number, comment: string, agreement: boolean) => {
    const review: UserFeedbackReview = {
      rating,
      // We transform the content of the message because:
      // 1. encodeURIComponent => to correctly decode the accents on the NodeJS side - https://stackoverflow.com/a/30106551/26457
      // 2. btoa => mainly to pass possible WAF checks that may block the request otherwise...
      content: btoa(encodeURIComponent(comment)),
      date: new Date().toISOString(),
      url: window.location.toString(),
      agreement,
      campaign: screen.campaign,
      // context: screen.context, // Is it needed in this kind of Splash-Screen?
    };
    this.setState({ ...this.state, feedbackResponse: 'submitting' });
    this.logDebug('Sending feedback', review);

    registerSplashScreenEvent('feedback.send');
    this.callSplashScreenAPI<UserFeedbackReview, any>(
      'feedback',
      `${this.feedbackEndpoint}/${this.props.applicationId}`,
      'POST',
      review,
    )
      .then((data: UserFeedbackReviewResponse) => {
        this.logDebug('Response from Feedback:', data);
        this.setState({ ...this.state, feedbackResponse: 'feedback-received' });
      })
      .catch(() => {
        this.setState({ ...this.state, feedbackResponse: 'error' });
      });
    // this.hidingModal();
  };

  /**
   * The modal has been hidden by the user.
   */
  hidingModal() {
    if (this.state.show) {
      this.props.onHide();
    }
    this.setState({
      show: false,
      screen: null,
    });
  }

  render() {
    if (!this.state.show) {
      return null;
    }
    if (this.props.mode === 'active') {
      const { screen } = this.state;
      if (!screen) {
        return null;
      }

      if (screen.type === 'feedback') {
        return (
          <FeedbackContent
            translator={this.props.translator}
            screen={screen}
            onClose={() => this.hidingModal()}
            onUserFeedback={this.sendUserFeedback}
            feedbackResponse={this.state.feedbackResponse}
          />
        );
      }
      return (
        <BasicModal onClose={() => this.hidingModal()} size="lg" className="border border-primary">
          <BasicModalHeader>
            {screen.title && <h3 className="modal-title">{screen.title}</h3>}
            {screen.logo && (
              <img className="sgwt-splash-screen-application-logo" src={screen.logo} alt="Application logo" />
            )}
          </BasicModalHeader>
          <BasicModalBody>
            <div dangerouslySetInnerHTML={{ __html: screen.content }} />
          </BasicModalBody>
          {screen.footer && (
            <BasicModalFooter>
              <span dangerouslySetInnerHTML={{ __html: screen.footer }} />
            </BasicModalFooter>
          )}
        </BasicModal>
      );
    }
    // In case we want to display the history but we do not have any screen to display, we return a span to avoid a
    // screen blink.
    if (this.state.loadingHistory) {
      return null;
    }
    if (this.state.history.length === 0) {
      return (
        <BasicModal onClose={() => this.hidingModal()} size="md" className="border border-warning">
          <BasicModalHeader className="pt-5 text-center">
            <div className="d-flex align-items-center flex-column w-100">
              <div
                className="rounded-circle bg-warning-alpha-md d-flex align-items-center justify-content-center"
                style={{ width: '3rem', height: '3rem' }}
              >
                <i className="sgwt-widgets-icon icon-md text-warning line-height-1 mt-n1">
                  <SvgIcon type="warning" />
                </i>
              </div>
            </div>
          </BasicModalHeader>
          <BasicModalBody className="pb-5 text-center">
            <h5>{this.props.translator.translate('noImportantMessages')}</h5>
          </BasicModalBody>
        </BasicModal>
      );
    }
    return (
      <SplashScreensCarousel
        onClose={() => {
          this.hidingModal();
        }}
        screens={this.state.history}
      />
    );
  }
}
