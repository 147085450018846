import React, { ReactNode } from 'react';
import { SvgIcon } from './SvgIcon';

export interface IBasicModalHeaderProps {
  className?: string;
  title?: string;
  onClose?: () => void;
  children?: ReactNode;
}

export interface IBasicModalBodyProps {
  className?: string;
  children?: ReactNode;
}

export interface IBasicModalFooterProps {
  className?: string;
  children?: ReactNode;
}

export interface IBasicModalProps {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  onClose: () => void;
  children?: ReactNode;
}

export const BasicModalHeader = (props: IBasicModalHeaderProps) => {
  if (props.title) {
    return (
      <div className={`modal-header position-relative ${props.className}`}>
        <h3 className="modal-title">{props.title}</h3>
        <button
          type="button"
          className="btn btn-link sgwt-account-center-close position-absolute flex-center"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onClose}
        >
          <SvgIcon width={24} height={24} type="close" />
        </button>
      </div>
    );
  }
  return <div className={`modal-header ${props.className}`}>{props.children}</div>;
};

export const BasicModalBody = (props: IBasicModalBodyProps) => (
  <div className={`modal-body ${props.className}`}>{props.children}</div>
);

export const BasicModalFooter = (props: IBasicModalFooterProps) => (
  <div className={`modal-footer ${props.className}`}>{props.children}</div>
);

export const BasicModal = (props: IBasicModalProps) => {
  let modalContent: HTMLDivElement | null;

  /**
   * Check if the click is made outside the modal content, so we can close it.
   *
   * Case 2: on Bootstrap, we can close the modal with the attribute
   * `data-dismiss="modal"` (Bootstrap 4) or `data-bs-dismiss="modal"` (Bootstrap 5).
   * It is not supported by react-bootstrap, so we fake it.
   */
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const element = event.target as HTMLElement;
    if (modalContent && !modalContent.contains(event.target as Node)) {
      props.onClose();
    } else if (
      element.getAttribute('data-dismiss') === 'modal' ||
      element.getAttribute('data-bs-dismiss') === 'modal'
    ) {
      props.onClose();
    }
  };

  return (
    <div>
      <div
        className="modal fade show d-block"
        id="basicModal"
        role="dialog"
        onClick={(event) => {
          handleClick(event);
        }}
        aria-hidden="true"
      >
        <div className={`modal-dialog ${props.size ? 'modal-' + props.size : ''}`} role="document">
          <div
            className={`modal-content shadow-max ${props.className}`}
            ref={(elt) => {
              modalContent = elt;
            }}
          >
            {props.children}
          </div>
        </div>
      </div>

      <div className="modal-backdrop fade show" />
    </div>
  );
};
