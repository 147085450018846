import { BUS_ACCESS_TOKEN, BUS_GLOBAL_LANGUAGE } from './auth/bus-topics';
import { getSgwtConnectLibrary } from './sgwt-widgets-utils';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageVersion = require('../../package.json').version;

export enum SgwtWidgetName {
  ACCOUNT_CENTER = 'sgwt-account-center',
  CONNECT = 'sgwt-connect',
  HELP_CENTER = 'sgwt-help-center',
  MINI_FOOTER = 'sgwt-mini-footer',
  SPLASH_SCREEN = 'sgwt-splash-screen',
  WEB_ANALYTICS = 'sgwt-web-analytics',
}

interface IMonitoringEvent {
  applicationId?: string;
  cdn: string | null;
  date: string;
  domain: string;
  event: string;
  eventDetails?: string;
  language?: string;
  sgbs: string;
  url: string;
  userAgent: string;
  version: string;
  widget: SgwtWidgetName;
}

declare global {
  interface Window {
    __sgwtWidgetsEventsQueue?: {
      events: IMonitoringEvent[];
      timeoutId: number | null;
    };
  }
}

const HOMOLOGATION_API = 'https://widgetmanagement-uat.sgmarkets.world.socgen';
const PRODUCTION_API = 'https://shared.sgmarkets.com';
const ENDPOINT = 'api/v1/monitoring';

// Time in ms waited before sending the event(s) to the API...
const WAITING_TIME_BEFORE_SENDING_EVENTS = 5000;

function getFromWidgetBus(topic: string): string | undefined {
  if (window.SGWTWidgetConfiguration && window.SGWTWidgetConfiguration.bus) {
    return window.SGWTWidgetConfiguration.bus.dangerouslyGetCurrentValue(topic) as string | undefined;
  }
  return undefined;
}

class SgwtWidgetsMonitoring {
  private apiEndpoint: string;
  private applicationId: string | undefined;
  private cdnUrls: Record<string, string | null>;

  constructor() {
    if (!window.__sgwtWidgetsEventsQueue) {
      this.initializeQueue();
    }
    const env: string | null | undefined = window.SGWTWidgetConfiguration
      ? window.SGWTWidgetConfiguration.environment
      : null;
    const prodEnvironment = !env || env.toLowerCase() === 'prod' || env.toLowerCase() === 'production';
    this.apiEndpoint = `${prodEnvironment ? PRODUCTION_API : HOMOLOGATION_API}/${ENDPOINT}`;
    this.cdnUrls = {};
  }

  private initializeQueue() {
    window.__sgwtWidgetsEventsQueue = {
      events: [],
      timeoutId: null,
    };
  }

  private getToken(): string | undefined | null {
    // Get the sgwtConnect from the widget or from the window object...
    const sgwtConnect: any = getSgwtConnectLibrary();
    // Get the authorization token...
    if (sgwtConnect) {
      return sgwtConnect.getAuthorizationHeader();
    }
    const fromBus = getFromWidgetBus(BUS_ACCESS_TOKEN);
    return fromBus || null;
  }

  private sendEvents() {
    if (!window.__sgwtWidgetsEventsQueue) {
      return;
    }
    if (window.__sgwtWidgetsEventsQueue.events.length > 0) {
      const events = [...window.__sgwtWidgetsEventsQueue.events];
      window.__sgwtWidgetsEventsQueue.events = [];
      const token = this.getToken();
      const options: any = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(events),
      };
      if (token) {
        options.headers.Authorization = token;
      }
      fetch(this.apiEndpoint, options).catch((err) => console.log('monitoring not sent due to error', err));
    }
    window.__sgwtWidgetsEventsQueue.timeoutId = null;
  }

  private getCdnPath(widget: string): string | null {
    let path = this.cdnUrls[widget];
    if (path !== undefined) {
      return path;
    }
    const scriptTag = document.querySelector<HTMLScriptElement>(`script[src*="${widget}.js"]`);
    if (scriptTag) {
      path = scriptTag.src;
    } else {
      path = null;
    }
    this.cdnUrls[widget] = path;
    return path;
  }

  private currentLocation() {
    const url = window.location.toString();
    const domain = url
      .substring(0, url.indexOf('/', 8)) // remove the sub-routes to keep the domain
      .replace(/^https?:\/\//, '') // remove the protocol part
      .replace(/:\d+/, ''); // remove the port
    return { url, domain };
  }

  public queueEvent(widget: SgwtWidgetName, eventName: string, eventDetails?: string | null) {
    if (!window.__sgwtWidgetsEventsQueue) {
      this.initializeQueue();
    }

    const language = getFromWidgetBus(BUS_GLOBAL_LANGUAGE);
    const event: IMonitoringEvent = {
      applicationId: this.applicationId,
      date: new Date().toISOString(),
      event: eventName,
      cdn: this.getCdnPath(widget),
      eventDetails: eventDetails || undefined,
      language,
      userAgent: window.navigator.userAgent,
      sgbs: 'v4',
      version: packageVersion,
      widget,
      ...this.currentLocation(),
    };

    window.__sgwtWidgetsEventsQueue!.events.push(event);
    if (window.__sgwtWidgetsEventsQueue!.timeoutId) {
      // Some events are already in the queue, so we reset the timeout...
      window.clearTimeout(window.__sgwtWidgetsEventsQueue!.timeoutId as number);
    }
    window.__sgwtWidgetsEventsQueue!.timeoutId = window.setTimeout(
      () => this.sendEvents(),
      WAITING_TIME_BEFORE_SENDING_EVENTS,
    );
  }

  public widgetLoaded(widget: SgwtWidgetName, applicationId?: string | null, configuration?: any | null) {
    this.applicationId = applicationId || undefined;
    let conf;
    if (configuration) {
      conf = Object.keys(configuration)
        .map((key: string) => `${key}=${configuration[key]}`)
        .join('; ')
        .trim();
    }
    this.queueEvent(widget, 'loaded', conf); // configuration ? JSON.stringify(configuration) : undefined);
  }
}

const monitoring = new SgwtWidgetsMonitoring();

export function startWidgetMonitoring(
  widget: SgwtWidgetName,
  applicationId?: string | null,
  configuration?: any | null,
) {
  monitoring.widgetLoaded(widget, applicationId, configuration);
}

export function registerEvent(widget: SgwtWidgetName, eventName: string, eventDetails?: string | null) {
  monitoring.queueEvent(widget, eventName, eventDetails);
}

export function registerAccountCenterEvent(eventName: string, eventDetails?: string | null) {
  registerEvent(SgwtWidgetName.ACCOUNT_CENTER, eventName, eventDetails);
}

export function registerMiniFooterEvent(eventName: string, eventDetails?: string | null) {
  registerEvent(SgwtWidgetName.MINI_FOOTER, eventName, eventDetails);
}

export function registerHelpCenterEvent(eventName: string, eventDetails?: string | null) {
  registerEvent(SgwtWidgetName.HELP_CENTER, eventName, eventDetails);
}

export function registerSplashScreenEvent(eventName: string, eventDetails?: string | null) {
  registerEvent(SgwtWidgetName.SPLASH_SCREEN, eventName, eventDetails);
}

export function registerMainIssue(widget: SgwtWidgetName, error: Error) {
  registerEvent(widget, 'general-issue', `${error.message}: ${error.stack}`);
}
