import React from 'react';
import { BasicModal, BasicModalBody, BasicModalFooter, BasicModalHeader } from '../../../common/components/BasicModal';
import { SvgIcon } from '../../../common/components/SvgIcon';
import { registerSplashScreenEvent } from '../../../common/monitoring';
import { ISplashScreen } from '../sgwt-splash-screen.types';

export interface ISplashScreensCarouselProps {
  onClose: () => void;
  screens: ISplashScreen[];
}

export interface ISplashScreensCarouselState {
  currentSlide: number;
  nextSlide: number | null;
  prepareLeaving: boolean;
  direction: 'next' | 'prev';
  leaving: 'left' | 'right' | null;
}

export default class SplashScreensCarousel extends React.Component<
  ISplashScreensCarouselProps,
  ISplashScreensCarouselState
> {
  public state: ISplashScreensCarouselState = {
    currentSlide: 0,
    nextSlide: null,
    prepareLeaving: false,
    leaving: null,
    direction: 'next',
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown, false);
  }

  private startAnimation(direction: 'next' | 'prev', nextSlide: number) {
    this.setState({
      prepareLeaving: true,
      direction: direction,
      nextSlide: nextSlide,
    });

    // Start the animation by putting 'next' or 'prev' CSS class on the new slide to show...
    setTimeout(() => {
      this.setState({
        leaving: direction === 'next' ? 'left' : 'right',
      });
    }, 100);

    // Now set the next slide as 'active'.
    setTimeout(() => {
      this.setState({
        direction: 'next',
        leaving: null,
        prepareLeaving: false,
        currentSlide: nextSlide,
        nextSlide: null,
      });
    }, 600);
  }

  private nextScreen() {
    const nextSlide = (this.state.currentSlide + 1) % this.props.screens.length;
    this.startAnimation('next', nextSlide);
    registerSplashScreenEvent('history.next-screen');
  }

  private previousScreen() {
    const nextSlide = this.state.currentSlide === 0 ? this.props.screens.length - 1 : this.state.currentSlide - 1;
    this.startAnimation('prev', nextSlide);
    registerSplashScreenEvent('history.previous-screen');
  }

  private getSlideClasses(index: number, mainClass: string): string[] {
    const classes = [mainClass];
    // Is it a slide that can be moved? Either the current one or the next one to display for animations.
    let slideToMove = false;

    if (index === this.state.currentSlide) {
      classes.push('active');
      slideToMove = true;
    }

    // For the next slide to show, set the `prev` or `next` class.
    if (this.state.direction && this.state.prepareLeaving && index === this.state.nextSlide) {
      classes.push(this.state.direction);
      slideToMove = true;
    }
    // Move the slide left or right.
    if (this.state.leaving && slideToMove) {
      classes.push(this.state.leaving);
    }
    return classes;
  }

  /**
   * On Bootstrap, we can close the modal with the attribute `data-dismiss="modal"`.
   * It is not supported by react-bootstrap, so we fake it.
   * @param {React.MouseEven} evt The click event.
   */
  panelClick(evt: React.MouseEvent) {
    const element = evt.target as HTMLElement;
    if (element.getAttribute('data-dismiss') === 'modal') {
      this.props.onClose();
    }
  }

  render() {
    if (this.props.screens.length === 0) {
      return null;
    }
    const screen = this.props.screens[this.state.currentSlide];

    // `<p dangerouslySetInnerHTML={{__html: '<strong>Hello</strong> world!'}}/>` does not work with
    // preact-compat + react-bootstrap.
    // Possible issue: https://github.com/developit/preact/issues/844
    // workaround: https://github.com/developit/preact/issues/844#issuecomment-325654148
    const InnerHTMLHelper = (props: { content: string }) => <div dangerouslySetInnerHTML={{ __html: props.content }} />;

    // Define slides
    const slides = this.props.screens.map((screen, index) => {
      const classes = this.getSlideClasses(index, 'carousel-item');
      return (
        <div
          key={`screen-${screen.name}`}
          className={classes.join(' ')}
          onClick={(evt: React.MouseEvent) => {
            this.panelClick(evt);
          }}
        >
          <InnerHTMLHelper content={screen.content} />
        </div>
      );
    });

    // Define titles
    const titles = this.props.screens.map((screen, index) => {
      const classes = this.getSlideClasses(index, 'title');
      return (
        <div key={`screen-${screen.name}`} className={classes.join(' ')}>
          {screen.title}
        </div>
      );
    });

    const footers = this.props.screens.map((screen, index) => {
      const classes = this.getSlideClasses(index, 'carousel-item');
      return (
        <div
          key={`screen-${screen.name}`}
          className={`${classes.join(' ')}`}
          onClick={(evt: React.MouseEvent) => {
            this.panelClick(evt);
          }}
        >
          {screen.footer && <InnerHTMLHelper content={screen.footer} />}
        </div>
      );
    });

    const multi = this.props.screens.length > 1;
    registerSplashScreenEvent('history.open');

    return (
      <BasicModal size="lg" onClose={this.props.onClose}>
        <BasicModalHeader className="sgwt-splash-screen-carousel-title">
          <h3 className="modal-title">{titles}</h3>
          {screen.logo && (
            <img className="sgwt-splash-screen-application-logo" src={screen.logo} alt={`${screen.application} logo`} />
          )}
        </BasicModalHeader>
        <BasicModalBody className="sgwt-splash-screen-carousel-body">
          <div className="carousel slide" data-ride="carousel">
            {/* Slides */}
            <div className="sgwt-splash-screen carousel-inner" role="listbox">
              {slides}
            </div>

            {/* Previous / Next controls */}
            {multi && (
              <button className="carousel-control-prev" data-slide="prev" onClick={() => this.previousScreen()}>
                <i className="sgwt-widgets-icon display-3 text-primary">
                  <SvgIcon type="keyboard_arrow_left" />
                </i>
                <span className="sr-only visually-hidden">Previous</span>
              </button>
            )}
            {multi && (
              <button className="carousel-control-next" data-slide="next" onClick={() => this.nextScreen()}>
                <i className="sgwt-widgets-icon display-3 text-primary">
                  <SvgIcon type="keyboard_arrow_right" />
                </i>
                <span className="sr-only visually-hidden">Next</span>
              </button>
            )}
          </div>
        </BasicModalBody>
        <BasicModalFooter className="sgwt-splash-screen-carousel-footer">
          <div className="carousel slide w-100 text-right" data-ride="carousel">
            {/* Footers */}
            <div className="sgwt-splash-screen carousel-inner" role="listbox">
              {footers}
            </div>
          </div>
        </BasicModalFooter>
      </BasicModal>
    );
  }

  private handleKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Echap' || event.which === 27) {
      this.props.onClose();
    } else if (event.key === 'ArrowLeft' || event.which === 37) {
      this.previousScreen();
    } else if (event.key === 'ArrowRight' || event.which === 39) {
      this.nextScreen();
    }
  };
}
