import React, { useState } from 'react';
import { BasicModal, BasicModalBody, BasicModalFooter, BasicModalHeader } from '../../../common/components/BasicModal';
import { RatingSelector } from '../../../common/components/RatingSelector';
import { SvgIcon } from '../../../common/components/SvgIcon';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { FeedbackResponse, ISplashScreen } from '../sgwt-splash-screen.types';

interface FeedbackContentProps extends ITranslatorProps {
  screen: ISplashScreen;
  onUserFeedback: (screen: ISplashScreen, rating: number, comment: string, agreement: boolean) => void;
  onClose: () => void;
  feedbackResponse: FeedbackResponse;
}

interface FeedbackReceivedProps extends ITranslatorProps {
  ok: boolean;
}

const FeedbackReceived = ({ translator, ok }: FeedbackReceivedProps) => {
  const clazz = ok ? 'success' : 'danger';
  return (
    <BasicModalBody>
      <div className="align-self-center w-100">
        <i className={`icon bg-${clazz} display-3 mb-3 p-1`} style={{ borderRadius: '50%' }} aria-hidden="true">
          <SvgIcon type={ok ? 'check' : 'close'} className="mt-n2" width={48} height={48} />
        </i>
        <p className={`mb-0 text-${clazz} display-5`}>
          {translator.translate(`feedback.response.${ok ? 'ok' : 'error'}`)}
        </p>
      </div>
    </BasicModalBody>
  );
};

export const FeedbackContent = ({
  screen,
  translator,
  onUserFeedback,
  onClose,
  feedbackResponse,
}: FeedbackContentProps) => {
  const [rating, setRating] = useState(4);
  const [comment, setComment] = useState('');
  const [contactMe, setContactMe] = useState(false);
  const changeRating = (newRating: number) => setRating(newRating);

  const leaveFeedback = () => onUserFeedback(screen, rating, comment, contactMe);

  const responseReceived = feedbackResponse === 'feedback-received' || feedbackResponse === 'error';

  return (
    <BasicModal onClose={onClose} size="lg" className="border border-primary">
      <BasicModalHeader>
        {screen.title && <h3 className="modal-title">{screen.title}</h3>}
        {screen.logo && (
          <img className="sgwt-splash-screen-application-logo" src={screen.logo} alt="Application logo" />
        )}
      </BasicModalHeader>

      {responseReceived ? (
        <FeedbackReceived translator={translator} ok={feedbackResponse === 'feedback-received'} />
      ) : (
        <BasicModalBody>
          <div dangerouslySetInnerHTML={{ __html: screen.content }} />
          <div className="w-100 d-flex justify-content-center my-3">
            <RatingSelector
              container="form"
              isValid={!!rating}
              rating={rating}
              ratingSelected={changeRating}
              starSize={48}
              translator={translator}
            />
          </div>
          <label htmlFor="feedback-comment" className="form-label">
            {translator.translate('feedback.comment')}
          </label>
          <textarea
            id="feedback-comment"
            className="form-control my-1"
            placeholder={translator.translate('feedback.placeholder')}
            cols={30}
            rows={5}
            value={comment}
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => setComment(evt.target.value)}
          />
          {rating < 3 && (
            <div className="alert alert-discreet-warning bg-warning-alpha-md text-warning d-flex my-3 p-3">
              <SvgIcon className="me-3 mr-3" type="warning" width={32} height={32} />
              <div className="text-start">{translator.translate('feedback.lowRatingWarning')}</div>
            </div>
          )}
          <div className="form-check mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="message-contact"
              checked={contactMe}
              onChange={() => setContactMe(!contactMe)}
            />
            <label className="form-check-label" htmlFor="message-contact">
              {translator.translate('feedback.contactMe')}
            </label>
          </div>
          <hr />
          <div className="text-secondary text-small">{translator.translate('feedback.disclaimer')}</div>
        </BasicModalBody>
      )}

      <BasicModalFooter>
        {screen.footer && <span dangerouslySetInnerHTML={{ __html: screen.footer }} />}
        <button
          className={`btn btn-${responseReceived ? 'primary' : 'flat-secondary'} btn-lg ml-3 ms-3`}
          onClick={onClose}
        >
          {translator.translate('feedback.btn.close')}
        </button>

        {feedbackResponse === null ? (
          <button className="btn btn-primary btn-lg ml-3 ms-3" onClick={leaveFeedback}>
            {translator.translate('feedback.btn.submit')}
          </button>
        ) : feedbackResponse === 'submitting' ? (
          <button className="btn btn-primary btn-lg btn-icon-start ml-3 ms-3 loading" disabled={true}>
            <span className="loader mr-1 me-1" />
            <i className="icon">close</i>
            {translator.translate('feedback.btn.submit')}
          </button>
        ) : null}
      </BasicModalFooter>
    </BasicModal>
  );
};
